import React, { useEffect, useMemo, useRef } from 'react';
import { Affix, Col, Row } from 'antd';
import ContainerComponent from '../../../helpers/components/container.component';
import PurchaseSummary from './purchase_summary';
import PurchaseStep from './purchase_step';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import MobileBottomCheckout from '../mobiles/mobile_bottom_checkout';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import SpecialLink from './special_link';
import { set_liability_waiver } from '../../../actions/ticket.action';
import moment from 'moment';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';
import TicketTypeLoading from './ticket_type_loading';
import Address from './address';

const PurchaseContent = ({park, loading}) => {
    const ticketRedux = useSelector(state => state.ticket);
    const purchaseRedux = useSelector(state => state.purchase);
    const dispatch = useDispatch();
    const containerRef = useRef();
    const affixRef = useRef();

    const initializePurchase = () => {
        const purchaseItemString = localStorage.getItem('purchase_item');
        const expirationDateString = localStorage.getItem('purchase_expiration');
        if(purchaseItemString){
            const purchaseItem = JSON.parse(purchaseItemString);
            const is_active = moment(expirationDateString)?.isAfter(moment()); //not yet expired
            if(is_active){ //if not yet expired, then put into redux
                dispatch(set_purchase_detail(purchaseItem));
            }

        }
    }

    const bannerQuill = useMemo(() => {
        let banner_quill;
        try{
            banner_quill = JSON.parse(park?.banner_text)
        }catch(err){

        }
        return banner_quill;
    }, [park])

    const closeLiabilityWaiver = (is_read) => {
        dispatch(set_liability_waiver(false))
        if(!is_read){
            dispatch(set_purchase_detail({...purchaseRedux, liability_waiver_checked : false,}));
        }
    }

    useEffect(() => {
        initializePurchase();
    }, []);

    return (
        <>
            <div ref={containerRef} className='purchase-ticket-container'>
                <ContainerComponent span={22} bottom={20}>
                    <Row gutter={[20, 20]} justify={'center'}>
                        <Col xs={24} md={16} span={16}>
                            {/* In Case Razer want footer */}
                            <div> 
                            {
                                loading ? (
                                    <>
                                        <div style={{padding:24}}>
                                            {/* <Skeleton active /> */}
                                            <TicketTypeLoading />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        {
                                            park ?
                                            (
                                                <>
                                                    <div style={{textAlign:'left', padding : '0 24px'}}>
                                                        {
                                                            ticketRedux?.special_link &&
                                                            (
                                                                <>
                                                                    <SpecialLink />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    
                                                    <div style={{textAlign:'left',}}>
                                                        <Address address={park?.address} description={park?.description} />
                                                        <PurchaseStep park={park} />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <SimpleNotFoundComponent 
                                                    title={`Park not found`}
                                                    description={`Something went wrong while looking for the park. Please contact administrator.`}
                                                    isBack
                                                    />
                                                </>
                                            )
                                        }
                                        
                                    </>
                                )
                            }
                            </div>
                        </Col>
                        
                        <Col xs={0} md={8} span={8} style={{ height:'auto', position:'sticky', top : 0}}>
                            <div>
                                <Affix
                                offsetTop={bannerQuill ? 148 : 108}
                                ref={affixRef}
                                >
                                    <EntranceMotion initialY={-100} duration={1} delay={0}>
                                        <PurchaseSummary park={park} />
                                    </EntranceMotion>
                                </Affix>
                            </div>
                        </Col>
                    </Row>

                    {/* mobile view */}
                    <MobileBottomCheckout park={park} />


                    {/* Liability Waiver Modal */}
                    {/* <Modal
                    style={{top:20,}}
                    open={ticketRedux?.liability_waiver_modal}
                    footer={false}
                    title="Liability Waiver"
                    width={800}
                    closable={false}
                    onCancel={() => closeLiabilityWaiver(false)}
                    >
                        <div>
                            <Space direction='vertical' size={12}>
                                <div style={{textAlign:'center'}}>
                                    <Image preview={false} src={`/cibanners/logo/${park?.park_code}.svg`} style={{ maxHeight: 80 }}/>
                                </div>
                                <div>
                                    <LiabilityWaiverModal park_id={park?.park_id} />
                                </div>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', }}>I have read this liability waiver, fully understand its terms, understand that I have given up substantial rights by selecting this checkbox, and do so freely and voluntarily without any inducement.</span>
                                </div>
                                <Divider />
                                <div>
                                    <Button onClick={() => closeLiabilityWaiver(true)} type='primary' size='large' style={{width:'100%'}}>I Agree, Proceed</Button>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)'}}>By clicking the Proceed button below, I agree to all the terms and conditions stated above.</span>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Modal> */}
                </ContainerComponent>
            </div>
        </>
    );
}

export default PurchaseContent;