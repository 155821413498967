import { Button, List, Space, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import numberHelper from '../../../helpers/functions/number.helper';

const OrderTicketUserList = ({ discount_ticket_users }) => {
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state.ticket);
    const promotion_code = purchaseRedux?.promotion_code;
    const currency = ticketRedux?.park?.currency?.symbol;

    return (
        <>
            {
                purchaseRedux?.ticket_users?.length > 0 &&
                (
                    <>
                        <div style={{textAlign:'left', padding:'0 24px', marginTop:24,}}>
                            <span style={{fontWeight:500, fontSize:18, color:'var(--main-color)'}}>Tickets</span>
                        </div>
                        <div>
                            <List
                            size='large'
                            dataSource={purchaseRedux?.ticket_users}
                            renderItem={(item, index) => {
                                const { name, price, quantity, actual_price, ticket_applicable_user_id, } = item ?? {};

                                // check if promotion code apply for this ticket user
                                const discount_ticket_user = discount_ticket_users?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id);

                                const is_applicable_promotion_code = (promotion_code?.promotion_code_ticket_applicable_users?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id))
                                const get_price = (promotion_code?.is_stack ? price : actual_price);
                                const promotion_price = (get_price - discount_ticket_user?.discount_amount) > 0 ? (get_price - discount_ticket_user?.discount_amount) : 0;
                                const cal_price = is_applicable_promotion_code ? promotion_price : price;
                                const sub_total = cal_price?.toFixed(2) * quantity;

                                return (
                                        <List.Item>
                                            <EntranceMotion initialY={-50} duration={1} style={{width : '100%'}}>
                                                <div style={{fontSize:16, display:'flex', textAlign:'left'}}>
                                                    <div>
                                                        <div>
                                                            <span>{item?.name}</span>
                                                        </div>
                                                        <div>
                                                            <Space align='center'>
                                                                <div>
                                                                    <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                                                        {`${currency}${numberHelper?.commaNumber(sub_total)}`}
                                                                    </span>
                                                                </div>
                                                                {
                                                                    is_applicable_promotion_code ?
                                                                    (
                                                                        <>
                                                                            <div>
                                                                                <EntranceMotion>
                                                                                    <span className='ticket-label' style={{fontSize:12, textDecoration: 'line-through', color: 'var(--secondary-text-color)'}}>
                                                                                        {`${currency}${numberHelper?.commaNumber((get_price * quantity))}`}
                                                                                    </span>
                                                                                </EntranceMotion>
                                                                            </div>
                                                                            <div>
                                                                                <EntranceMotion delay={0.1}>
                                                                                    <Tag color='orange' style={{border:'none'}}>
                                                                                        {promotion_code?.promo_code}
                                                                                    </Tag>
                                                                                </EntranceMotion>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            {
                                                                                actual_price != price &&
                                                                                (
                                                                                    <>
                                                                                        <div>
                                                                                            <span className='ticket-label' style={{fontSize:12, textDecoration: 'line-through', color: 'var(--secondary-text-color)'}}>
                                                                                                {`${currency}${numberHelper?.commaNumber(actual_price * quantity)}`}
                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            </Space>
                                                            
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:'auto'}}>
                                                        <Space>
                                                            <div>
                                                                <span>
                                                                    {quantity}
                                                                </span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                
                                                </div>
                                            </EntranceMotion>
                                        </List.Item>
                                )
                            }}
                            />
                        </div>
                    </>
                )
            }
        </>
    );
}

export default OrderTicketUserList;