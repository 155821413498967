import { Affix, Button, Image, Space } from 'antd';
import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { reset_purchase_detail } from '../../../actions/purchase.action';
import { useDispatch, useSelector } from 'react-redux';

const CartDetail = ({ park }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);

    const startOver = () => {
        dispatch(reset_purchase_detail(park?.park_id));
        localStorage?.removeItem('purchase_item');
    }

    return (
        <>
            {/* <Affix
            offsetBottom={0}
            style={{width:'fit-content', marginLeft:'auto',}}
            >
                <EntranceMotion initialY={20}>
                    <div style={{ textAlign:'start', padding:4,background:'var(--secondary-button-color)', borderRadius:20, borderTopRightRadius:0, borderBottomRightRadius:0,}}>
                        <Space size={20}>
                            <Image src='/cibanners/cart-ticket.png' preview={false} width={40} height={40} />
                            <div>
                                <span style={{color:'var(--secondary-text-color)'}}>
                                    There are {purchaseRedux?.ticket_users?.reduce((a, b) => (a + b?.quantity), 0)} items in your cart
                                </span>
                            </div>
                            <div>
                                <Button
                                type='ghost'
                                // style={{ fontSize:12, }}
                                size='small'
                                onClick={startOver}
                                >
                                    <span style={{color:'#c45656', fontWeight:500,}}>
                                        Remove
                                    </span>
                                    
                                </Button>
                            </div>
                        </Space>
                    </div>
                </EntranceMotion>
            </Affix> */}

            <div>
                <Button
                type='text'
                style={{ width : '100%', }}
                // size='small'
                onClick={startOver}
                >
                    <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>
                        Reset
                    </span>
                </Button>
            </div>
        </>
    );
}

export default CartDetail;