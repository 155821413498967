import moment from "moment";

const initialState = {
    park_id : null,
    visit_date : null,
    ticket_type_id : null,
    ticket_users : [],
    add_ons : [],

    //additional
    promotion_code : null,
    preference_id : null,

    //contact info
    email : null,
    name : null,

    //liability waiver
    liability_waiver_checked : false,


    //final price
    final_price : 0,
}

const purchaseReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_PURCHASE_DETAIL':
            //store to localstorage
            let purchaseItem;
            if(action.is_null){
                purchaseItem = {
                    ...state, 
                    visit_date : action.visit_date,
                    ticket_type_id : action.ticket_type_id,
                    ticket_users : action.ticket_users,
                    add_ons : action.add_ons,
                    email : action.email,
                    park_id : action.park_id,
                    name : action.name,
                    promotion_code : action.promotion_code,
                    preference_id : action.preference_id,
                    liability_waiver_checked : action.liability_waiver_checked,
                    final_price : action.final_price,
                }
            }else{
                purchaseItem = {
                    ...state, 
                    visit_date : action.visit_date ?? state.visit_date,
                    ticket_type_id : action.ticket_type_id ?? state.ticket_type_id,
                    ticket_users : action.ticket_users ?? state.ticket_users,
                    add_ons : action.add_ons ?? state?.add_ons,
                    email : action.email ?? state.email,
                    park_id : action.park_id ?? state?.park_id,
                    name : action.name ?? state?.name,
                    promotion_code : action.promotion_code ?? state?.promotion_code,
                    preference_id : action.preference_id ?? state?.preference_id,
                    liability_waiver_checked : action.liability_waiver_checked ?? state?.liability_waiver_checked,
                    final_price : action.final_price ?? state?.final_price,
                }
            }
            
            //set to localStorage for next usage
            if(state?.park_id){
                //check if park id is available only set into localstorage
                localStorage.setItem('purchase_item', JSON.stringify(purchaseItem));
                //set expiration date
                const expiration_date = moment()?.add(1, 'days');
                localStorage.setItem('purchase_expiration', expiration_date);
            }
            
    
            return purchaseItem;
        case 'RESET_PURCHASE_DETAIL' :
            const purchaseState = {
                ...initialState,
                park_id : action.park_id,
            }
            return purchaseState;
        default :
            return state;
    }
}

export default purchaseReducer;