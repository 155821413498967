import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Row, } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import StepNoItem from './step_no_item';
import PurchaseParkApplicableTicketTypeItem from './purchase_park_applicable_ticket_type_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global.helper';
import { Carousel } from '@trendyol-js/react-carousel';

const PurchaseStep = ({park}) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state.purchase);
    const listItemRef = useRef();
    const parkRedux = useSelector(state => state?.park);
    const changeDetail = (key, value) => {
        dispatch(set_purchase_detail({
            [key] : value
        }))
    }

    const selectTicketType = (ticket_type_id) => {
        //when change ticket, reset the user list and add on list
        dispatch(set_purchase_detail({ticket_users : [], add_ons : []}));
        changeDetail('ticket_type_id', ticket_type_id);
    }

    return (
        <>
            <div style={{padding:24}}>
                <div id="step_1" className='step-1'>
                    <StepNoItem no={1} text={'Ticket Type'} image={'cibanners/step/ticket-type.png'}/>
                    <div style={{marginTop:12, padding : (globalHelper.isMobileScreen() ? 0 : ('0 12px')),}}>
                        <Row gutter={[12, 12]} justify="start">
                            {
                                park?.ticket_types?.sort((a, b) => a?.ParkApplicableTicketType?.sequence - b?.ParkApplicableTicketType?.sequence)?.map((ticket_type, index) => {
                                    const selected_ticket_type = ticket_type?.ticket_type_id === purchaseRedux?.ticket_type_id;
                                    const { image } = ticket_type?.ParkApplicableTicketType;
                                    return (
                                        <Col xs={12} sm={6} md={8} lg={6} span={6} key={index}>
                                            <EntranceMotion initialY={-50} duration={1} delay={index * 0.1} style={{height:'100%'}}>
                                                <Card
                                                className={`ticket-type-card ${selected_ticket_type ? 'selected' : ''}`}
                                                onClick={() => selectTicketType(ticket_type?.ticket_type_id)}
                                                style={{height:'100%', borderWidth:1.5, cursor : 'pointer', }}
                                                styles={{ body : { padding : 12, paddingTop : 12, }}}
                                                cover={
                                                    <div style={{padding : 8, paddingBottom : 0,}}>
                                                        <div style={{ height:'100%', width:'auto', textAlign:'center',}}>
                                                            <div style={{ background:'var(--secondary-button-color)', maxWidth:'100%'}}>
                                                                <Image preview={false} src={image} style={{objectFit:'contain', borderRadius:8,}}/>
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ margin : '8px 0', }}>
                                                            <Button onClick={() => selectTicketType(ticket_type?.ticket_type_id)} {...selected_ticket_type && ({ icon : <CheckOutlined /> })} style={{ width : '100%', ...(selected_ticket_type && ({ background : 'var(--lighten-main-color)', borderColor : 'var(--main-color)', color : 'var(--main-color)' }))}}>
                                                                {selected_ticket_type ? 'Selected' : 'Select'}
                                                            </Button>
                                                        </div> */}
                                                    </div>
                                                }
                                                >
                                                    <div style={{textAlign:'center',}}>
                                                        <div>
                                                            <span className='header-font' style={{fontWeight:'500', color : '#434343'}}>{ticket_type?.name}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{color:'#6f7287', fontSize:12,}}>{ticket_type?.ParkApplicableTicketType?.descriptions}</span>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </EntranceMotion>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
                
                {
                    purchaseRedux?.ticket_type_id &&
                    (
                        <>
                            <div
                            ref={listItemRef}
                            className='list-items' 
                            style={{marginTop:48}}
                            >
                                <PurchaseParkApplicableTicketTypeItem park={park} ticket_type={park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id)} />
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default PurchaseStep;