import { Button, notification } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import paymentService from '../../../services/payment/payment.service';
import ticketService from '../../../services/ticketing/ticket.service';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import globalHelper from '../../../helpers/functions/global.helper';
import { useHistory } from 'react-router';

const PurchaseButton = ({park, is_checkout, final_price,}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const purchaseRedux = useSelector(state => state.purchase);
    const ticketRedux = useSelector(state => state.ticket);
    const [state, setState] = useState({
        save_loading : false,
    });

    const is_no_ticket_purchased = !(purchaseRedux?.ticket_users?.length > 0 || purchaseRedux?.add_ons?.length > 0);
    const is_no_remaining_capacity = !(!ticketRedux?.is_capacity || (ticketRedux?.is_capacity && ticketRedux?.remaining_capacity > 0));
    // disabled button either is no ticket selected or no remaining capacity
    const is_disabled = (is_no_ticket_purchased || is_no_remaining_capacity);

    const purchaseTicket = async () => {
        setState(state => ({...state, save_loading : true}));
        const { visit_date, ticket_users, ticket_type_id, email, park_id, name, add_ons, promotion_code, liability_waiver_checked, preference_id, } = purchaseRedux ?? {};
        const { special_link } = ticketRedux;
        const purchaseTicketResponse = await ticketService.purchaseTicket({
            visit_date : globalHelper.parseDate(visit_date), ticket_users, ticket_type_id, email, park_id, name, add_ons, promotion_code : promotion_code?.promo_code, special_link_id : special_link?.special_link_id,
            liability_waiver_checked, preference_id,
            final_price, // this is to check whether calculation from front end is same with calculation with backend
        }).catch(err => {
            setState(state => ({...state, save_loading : false}));
        }).then(async (ticketResponse) => {
            // const ticket_id = (ticketResponse?.ticket?.ticket_id);
            const { ticket } = ticketResponse;
            const ticket_id = ticket?.ticket_id;
            const running_number = ticket?.running_number;

            //if there is error
            if(ticketResponse?.error){
                const error = ticketResponse?.error[0];
                const { message, type } = error ?? {};
                notification.warning({
                    message : 'Oops',
                    description : message,
                });
                setState(state => ({...state, save_loading : false}));
                //scroll to email
                dispatch(set_purchase_error({
                    [type] : true,
                }))
            }else{
                if(is_checkout){
                    const paymentLinkResponse = await paymentService.generatePaymentLink(park?.park_code, {
                        ticket_id
                    }).catch(err =>{
                        setState(state => ({...state, save_loading : false}));
                    });
    
                    //if success, redirect to payment link
                    if(ticketResponse){
                        setTimeout(() => {
                            // setState(state => ({...state, save_loading : false}));
                            window.location.href = paymentLinkResponse?.url;
                        }, 100);
                    }
                }else{
                    //go to review page
                    history.push(`/purchase/review/${running_number}`);
                }

                setState(state => ({...state, save_loading : false}));
                
            }
            
        });
    }

    return (
        <>
            <Button
            onClick={purchaseTicket}
            size='large'
            type="primary"
            style={{width:'100%', border:'none'}}
            disabled={is_disabled}
            loading={state?.save_loading}
            >
                <span style={{color: "var(--main-text-color)" , fontWeight:500}}>{is_checkout ? 'Check Out' : 'Review Order'}</span>
            </Button>
        </>
    );
}

export default PurchaseButton;