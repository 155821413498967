import React from 'react';
import { Switch } from 'react-router-dom';
import FaceConfig from '../containers/face_config';
import TicketUserFaceDetail from '../containers/face_config/detail';
import PaymentIndex from '../containers/payment';
import PublicRoute from './public.router';
import PurchaseIndex from '../containers/purchase';
import TicketIndex from '../containers/ticket';
import ChangeDateIndex from '../containers/change_date';
import ChangeDateSearch from '../containers/change_date/search';
import PaymentDateIndex from '../containers/payment/date';

const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Home */}
                <PublicRoute component={PurchaseIndex} path="/" exact restricted={false} />
                
                {/* Ticket Configuration */}
                <PublicRoute component={FaceConfig} path="/ticket/face/:token" exact restricted={false} />
                <PublicRoute component={TicketUserFaceDetail} path="/ticket/user/:ticket_user_id" exact restricted={false} />

                {/* Payment */}
                <PublicRoute component={PaymentIndex} path="/payment/:running_number" exact restricted={false} />
                <PublicRoute component={PaymentDateIndex} path="/payment/date/:running_number" exact restricted={false} />

                {/* Purchase */}
                {/* <PublicRoute component={PurchaseIndex} path="/purchase/ticket/:park_code" exact restricted={false} /> */}

                {/* Ticket */}
                <PublicRoute component={TicketIndex} path="/ticket/:ticket_id" exact restricted={false} />

                {/* Date Changing */}
                <PublicRoute component={ChangeDateIndex} path="/date/change/:token" exact restricted={false} />
                <PublicRoute component={ChangeDateSearch} path="/date/search" exact restricted={false} />

            </Switch>
        </>
    );
}

export default AppRouter;